import AuthGuard from 'app/auth/AuthGuard';
import administrationRoutes from 'app/views/administration/AdministrationRoutes';
import biographyRoutes from 'app/views/biography/BiographyRoutes';
import reportsRoutes from 'app/views/reports/ReportsRoutes'
import transactionRoutes from 'app/views/transaction/TransactionRoutes';
import sessionRoutes from 'app/views/sessions/SessionRoutes';
import NotFound from 'app/views/sessions/NotFound';
import { Navigate } from 'react-router-dom';
import MatxLayout from './components/MatxLayout/MatxLayout';

const routes = [
    {
        element: (
            <AuthGuard>
                <MatxLayout />
            </AuthGuard>
        ),
        children: [...administrationRoutes, ...biographyRoutes, ...reportsRoutes, ...transactionRoutes],
    },
    ...sessionRoutes,
    {
        path: '/',
        element: <Navigate to="biography/default" />
    },
    {
        path: '*',
        element: <NotFound />
    }
];

export default routes;